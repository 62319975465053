<template>
  <v-form
    class="public-page-form justify-center d-flex flex"
    @submit.prevent="createOrg">
    <v-card :flat="hasTwoPaneLayout">
      <v-card-text>
        <v-row>
          <v-col class="text-center">
            <span
              class="public-page-form__step">
              {{ $t('forms.step') }} 2 / 2
            </span>
            <h2
              v-if="hasTwoPaneLayout"
              class="public-page-form__title mb-2 ml-0 pl-0">
              {{ $t('projectsHome') }}
            </h2>
            <router-link
              v-else
              :to="{ name: 'login' }">
              <CyAppPublicLogo class="public-page-form__logo"/>
            </router-link>
          </v-col>
        </v-row>
        <v-row class="mx-6 text-center">
          <v-col class="description">
            <span>
              {{ $t('description') }}
            </span>
          </v-col>
        </v-row>
        <v-row class="mx-6 mt-4">
          <v-col>
            <v-text-field
              v-model="newOrganization.name"
              data-cy="name-field"
              :label="$t('forms.fieldOrgName')"/>
          </v-col>
        </v-row>
        <v-row class="ma-4">
          <v-col class="text-center pb-2">
            <CyButton
              data-cy="submit-btn"
              :loading="loading"
              :disabled="!newOrganization.name"
              type="submit">
              {{ $t('createOrg') }}
            </CyButton>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import { pagination as createAPIPage } from '@/utils/api'

export default {
  name: 'CyPageMarketplaceNewOrganization',
  data: () => ({
    loading: false,
    newOrganization: {
      name: '',
    },
  }),
  computed: {
    hasTwoPaneLayout () {
      return this.$route.meta?.layout?.isTwoPane
    },
  },
  methods: {
    ...mapActions('organization', [
      'CREATE_ORGANIZATION',
    ]),
    async createOrg () {
      this.$toggle.loading(true)
      const reqPage = createAPIPage(1, 100)
      const { $router, newOrganization: { name } } = this
      const organization = { name, canonical: this.$getSlug(name) }
      await this.CREATE_ORGANIZATION({ reqPage, $router, organization, parentCanonical: null })
      this.$router.replace({ name: 'dashboard', params: { orgCanonical: organization.canonical } })
      this.$toggle.loading(false)
    },
  },
  i18n: {
    messages: {
      en: {
        createOrg: 'Create @:Organization',
        description: 'The organization is the one place to manage all your projects, teams, stacks, and much more. Your subscription will be attached to this organization.',
        projectsHome: 'Let’s set up a home for all your projects.',
        title: '@:routes.marketplaceNewOrganization',
      },
      es: {
        createOrg: 'Crear @:Organization',
        description: 'La organización es el único lugar para administrar todos tus proyectos, equipos, stacks y mucho más. Su suscripción se adjuntará a esta organización.',
        projectsHome: 'Configuremos un hogar para todos tus proyectos.',
        title: '@:routes.marketplaceNewOrganization',
      },
      fr: {
        createOrg: `Créer l'@:Organization`,
        description: `L'organisation est l'endroit unique pour gérer tous vos projets, équipes, stacks et bien plus encore. Votre abonnement sera rattaché à cette organisation.`,
        projectsHome: 'Configurons un endroit pour tous vos projets.',
        title: '@:routes.marketplaceNewOrganization',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 745px) {
  .public-page-form__title {
    text-align: center;
  }
}
</style>
